import {
  FontSizes,
  IButtonStyles,
  mergeStyles,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Project } from "../../models";
import Loader from "../../components/Loader";
import { ProjectInCard } from "../../components/Cards/ProjectInCard";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { getAllProjects, getSearchedProjects } from "../redux/Projects/project";
import { colorPallete } from "../../assets/color";
import "./index.css";
import { useSelector } from "react-redux";

const retryButtonStyles: IButtonStyles = {
  root: {
    width: "20px",
    margin: "1%",
    alignItems: "center",
  },
};

const styles = {
  ProjectStackStyle: {
    width: "98%",
    height: "98%",
    alignItems: "flex-start",
    backgroundColor: colorPallete.white,
    flexDirection: "row",
  },
  TextDivStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  errorStyles: {
    display: "flex",
    justifyContent: "center",
    width: "90%",
    height: "18rem",
    alignItems: "center",
  },
  textStyles: {
    fontSize: FontSizes.size18,
    fontFamily:"Poppins"
  },
  loaderStyles: {
    display: "flex",
    justifyContent: "center",
    width: "90%",
    height: "18rem",
  },
  stackStyles: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    height: "100%",
    overflowX: "hidden",
    backgroundColor: "",
  },
};

export const Projects: FC<any> = (props) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isSearchEnabled = useAppSelector(
    (state) => state.isSearchEnabled.value
  );
  const searchParam = useAppSelector((state) => state.isSearchEnabled.text);
  const [projectData, setProjectData] = useState<Project[]>([]);
  const { isLoading, data, error, metadata } = useAppSelector(
    (state) =>
      isSearchEnabled ? state.fetchSearchedProject : state.fetchProjects,
  );
  const element = document.getElementById("myStack");

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    let width = screenWidth <= 1350 ? screenWidth : 1350;
    if (data && data.length * 270 + 260 > width) {
      props?.setArrowButtons(true);
    } else {
      props?.setArrowButtons(false);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data, screenWidth]);

  useEffect(() => {
    if (data && data.length > 0) {
      setProjectData(data);
    } else {
      setProjectData([]);
    }
  }, [data, metadata]);

  useEffect(() => {
    isSearchEnabled && 
    dispatch(getSearchedProjects({
      projectName:searchParam,
      limit:120,
      offset:0
    }));
  }, [searchParam]);

  useEffect(() => {
    if (data.length === 0 || isError)
      dispatch(
        getAllProjects({
          limit: 3000,
          offset: 0,
        })
      );
  }, [isError]);

  useEffect(() => {
    if (props.left && element) {
      element.scrollLeft += props.left;
    }
  }, [props.left]);

  const loaderJSX = useMemo(() => {
    return isLoading ? (
      <Stack className={mergeStyles(styles.loaderStyles)}>
        <Loader />
      </Stack>
    ) : null;
  }, [isLoading]);

  const errorJSX = !isLoading && error !== "" && (
    <Stack className={mergeStyles(styles.errorStyles)}>
      <Text className={mergeStyles(styles.textStyles)}>{t("loader")}</Text>
      <PrimaryButton
        styles={retryButtonStyles}
        onClick={() => setIsError(!isError)}
      >
        {t("retry")}
      </PrimaryButton>
    </Stack>
  );

  return (
    projectData && (
      <div className="Projects" id="myStack">
        {loaderJSX}
        {errorJSX}
        {projectData.map((project, i) => {
          return <ProjectInCard key={project.projectId} project={project} />;
        })}

        {!isLoading && data.length === 0 && !errorJSX && (
          <div style={styles.TextDivStyle}>
            <Text className={mergeStyles(styles.textStyles)}>
              {t("no_projects_found")}
            </Text>
          </div>
        )}
      </div>
    )
  );
};
