import { FC, useEffect, useState } from "react";
import { FunctionalityButton } from "../FunctionalityButton";
import { VerticalStack } from "../../../components/Stack";
import { IStackStyles, Text } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { functionalityState } from "../../redux/DocumentStackSlice";
import { colorPallete } from "../../../assets/color";
import "./index.css";
import useIsSignedIn from "../../../hooks/useIsSignedIn";
import { ProjectService } from "../../../services/ProjectService";

const verticalStackStyles: IStackStyles = {
  root: {
    minHeight: "9rem",
    width: "100%",
    backgroundColor: "",
    justifyContent: "space-evenly",
    marginTop: "1%",
  },
};

const click2 = () => {};
interface PipelineItemProps {
  title: string;
  value: string | number;
}
const PipelineItem: FC<PipelineItemProps> = ({
  title,
  value,
}: PipelineItemProps) => {
  return (
    <div
      style={{
        border: "1px solid grey",
        borderRadius: "0.4rem",
        height: "90px",
        padding: "8px 0px",
        width: "150px",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          // height: "20%",
          borderBottom: "1px solid grey",
          textAlign: "center",
          color: "grey",
          fontWeight: "bolder",
        }}
      >
        {title}
      </div>
      <div
        style={{
          // height: "80%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "xx-large",
        }}
      >
        {value}
      </div>
    </div>
  );
};
interface PipelineProps {
  items: PipelineItemProps[];
}
const Pipeline: FC<PipelineProps> = ({ items }: PipelineProps) => {
  return (
    <div style={{ width: "fit-content", padding: "8px", alignSelf: "center" }}>
      <div
        style={{
          height: "20%",
          textAlign: "center",
          marginBottom: "4px",
          fontSize: "x-large",
        }}
      >
        My Pipeline
      </div>
      <div style={{ display: "flex", gap: "10px" }}>
        {items.map((item, index) => {
          return (
            <PipelineItem key={index} title={item.title} value={item.value} />
          );
        })}
      </div>
    </div>
  );
};
const demoItem = [
  {
    title: "Active",
    value: 1000,
  },
  {
    title: "Closed",
    value: 1000,
  },
  {
    title: "Value",
    value: "$1.4B",
  },
];
function formatMoney(value: any) {
  if (value === null || value === undefined) return "$0";

  const absValue = Math.abs(value);
  let formattedValue;

  if (absValue >= 1e12) {
    formattedValue = (value / 1e12).toFixed(1) + "T"; // Trillions
  } else if (absValue >= 1e9) {
    formattedValue = (value / 1e9).toFixed(1) + "B"; // Billions
  } else if (absValue >= 1e6) {
    formattedValue = (value / 1e6).toFixed(1) + "M"; // Millions
  } else if (absValue >= 1e3) {
    formattedValue = (value / 1e3).toFixed(1) + "K"; // Thousands
  } else {
    formattedValue = value.toFixed(2); // Less than a thousand
  }

  return `$${formattedValue}`;
}
export const FunctionalityButtonStack: FC = () => {
  const dispatch = useDispatch();
  function click() {
    dispatch(functionalityState("draft"));
  }

  function click1() {
    // dispatch(functionalityState("negotiate"));
  }
  const { t } = useTranslation();
  const [statuses, setStatuses] = useState([
    t("draft"),
    t("negotiate"),
    t("sign"),
  ]);
  const isSignedIn = useIsSignedIn();
  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        if (isSignedIn[0]) {
          const currentStatus: any = await ProjectService.getStatuses();
          setStatuses([
            `Active Projects - ${currentStatus.data.active}`,
            `Closed Projects - ${currentStatus.data.closed}`,
            `Closed Projects Value - ${formatMoney(currentStatus.data.value)}`,
          ]);
        } else {
          setStatuses([t("draft"), t("negotiate"), t("sign")]);
        }
      } catch (error) {
        console.error("Error fetching statuses:", error);
      }
    };

    fetchStatuses();
  }, [isSignedIn[0]]);
  return (
    <VerticalStack styles={verticalStackStyles}>
      {/* <Text
        style={{
          fontSize: "32px",
          color: colorPallete.black2,
          textAlign: "center",
          fontWeight: "500",
          lineHeight: "40px",
          fontStyle: "normal",
          fontFamily: "Poppins",
        }}
      >
        {t("secure_document_negotiation_made_easy")}
      </Text> */}
      {/* <Pipeline items={demoItem}/> */}
      <Text
        styles={{
          root: {
            fontSize: "20px",
            color: colorPallete.black2,
            paddingLeft: "15px",
            textAlign: "start",
            lineHeight: "22px",
            fontWeight: "400",
            fontStyle: "normal",
          },
        }}
        style={{fontFamily:"Poppins"}}
      >
        Pipeline
      </Text>
      <div className="ButtonStack">
        <FunctionalityButton
          buttonText={statuses[0]}
          color={colorPallete.btBlue}
          onClickFunction={click}
          flex={"1 1 30vw"}
          icon="draft"
        />
        <FunctionalityButton
          buttonText={statuses[1]}
          color={colorPallete.btGreen}
          onClickFunction={click1}
          flex={"1 1 30vw"}
          icon="negotiate"
        />
        <FunctionalityButton
          buttonText={statuses[2]}
          color={colorPallete.btRed}
          onClickFunction={click2}
          flex={"1 1 30vw"}
          icon="sign"
        />
      </div>
    </VerticalStack>
  );
};
