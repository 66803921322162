import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./navigation";
import { store } from "./modules/redux/store";
import { Provider } from "react-redux";
import { mergeStyles } from "@fluentui/react";
import { colorPallete } from "./assets/color";

function App() {

  return (
    <Provider store={store}>
      <BrowserRouter>
        <div data-testid="app-container" className={mergeStyles(styles.top)}>
          <div className={mergeStyles(styles.container)}>
            <AppRoutes />
          </div>
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;

const styles = {
  top: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "",
    height: "100vh",
    display: "flex",
  },
  container: {
    maxWidth: 1238,
    width: "91%",
    backgroundColor: "",
    marginVertical: 0,
    marginHorizontal: "auto",
    height: "100vh",
  },
  whiteWrapper: {
    backgroundColor: "white",
    marginBottom: "2%",
    height: "70px",
    width: "100%",
    zIndex: -6,
    top: 0,
  },
  greyWrapper: {
    backgroundColor: colorPallete.btLightBlue,
    marginBottom: "2%",
    height: "175px",
    width: "100%",
    zIndex: -9,
    top: 70,
  },
};
