import { FC, useState } from "react";
import {
  mergeStyles,
  IStackStyles,
  Text,
  Stack,
  ScreenWidthMinSmall,
  FontSizes,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { HorizontalStack, VerticalStack } from "../../../components/Stack";
import { Projects } from "..";
import { ViewAll } from "../../../components/ViewAll";
import { useNavigate } from "react-router-dom";
import useIsSignedIn from "../../../hooks/useIsSignedIn";
import { NewProject } from "../../../components/modals/NewProject";
import { colorPallete } from "../../../assets/color";
import SearchBar from "../../../components/SearchBox";

const styles = {
  headerStyle: {
    fontSize: "20px",
    color: colorPallete.black2,
    paddingLeft: "15px",
    textAlign: "start",
    lineHeight: "22px",
    fontWeight: "400",
    fontStyle: "normal",
  },
  IconStyle: {
    textAlign: "center",
    fontSize: "140px",
    color: colorPallete.btBlue,
    marginBottom: "4%",
  },
  IconDivStyle: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    border: "3px solid lightgrey",
    minHeight: "17.5rem",
    marginLeft: "1%",
    borderRadius: "8px",
  },
  ProjectTitleStackStyle: {
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "2%",
  },
  ProjectStackStyle: {
    width: "100%",
    alignItems: "center",
    minHeight: "19rem",
    backgroundColor: "",
    height: "20rem",
  },
  NewProjectStackStyle: {
    minWidth: "16rem",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "",
    paddingTop: "1%",
  },
  projectContainerStackStyle: {
    minWidth: ScreenWidthMinSmall,
    width: "82%",
    backgroundColor: "inherit",
  },
};

const verticalStackStyles: IStackStyles = {
  root: {
    marginBottom: "2%",
    paddingRight: "1%",
    width: "100%",
    minHeight: "20rem",
  },
};

export const ProjectStack: FC = () => {
  const [left, setLeft] = useState<number>(0);
  let distance = left;
  const [arrowButtons, setArrowButtons] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  function click() {
    navigate("project");
  }

  let isSignedIn = useIsSignedIn();

  const Buttons = () => {
    return (
      <>
        <Stack
          onClick={() => {
            if (left > 0) {
              let a = 0;
              setLeft((a -= 1200));
            } else {
              setLeft((distance -= 400));
            }
          }}
          style={{
            backgroundColor: colorPallete.white,
            left: 3,
          }}
          className="Icons"
        >
          <img src={require("../../../assets/Icons/leftIcon.png")} />
        </Stack>
        <Stack
          onClick={() => {
            if (left < 0) {
              let a = 0;
              setLeft((a += 1200));
            } else {
              setLeft((distance += 400));
            }
          }}
          style={{
            backgroundColor: colorPallete.white,
            right: -2,
          }}
          className="Icons"
        >
          <img src={require("../../../assets/Icons/rightIcon.png")} />
        </Stack>
      </>
    );
  };
  return (
    <VerticalStack styles={verticalStackStyles}>
      <HorizontalStack style={styles.ProjectTitleStackStyle}>
        <Text
          className={mergeStyles(styles.headerStyle)}
          style={{ fontFamily: "Poppins" }}
        >
          {t("projects")}
        </Text>
        {isSignedIn[0] && (
          <HorizontalStack>
            <SearchBar placeholder={t("search_project")} />
            <ViewAll onClickFunction={click} />
          </HorizontalStack>
        )}
      </HorizontalStack>
      <Stack className="ProjectStack">
        {isSignedIn[0] ? (
          <>
            <NewProject />
            <Stack style={styles.projectContainerStackStyle}>
              <HorizontalStack
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "inherit",
                  position: "relative",
                  paddingLeft: "10px",
                  left: 2,
                }}
              >
                {arrowButtons && <Buttons />}
                <HorizontalStack style={{ width: "99%" }}>
                  <Projects left={left} setArrowButtons={setArrowButtons} />
                </HorizontalStack>
              </HorizontalStack>
            </Stack>
          </>
        ) : (
          <Stack style={styles.IconDivStyle}>
            <Text style={{ fontSize: FontSizes.size20, fontFamily: "Poppins" }}>
              {t("login_to_view_projects")}
            </Text>
          </Stack>
        )}
      </Stack>
    </VerticalStack>
  );
};
